import React from 'react';
import GradientContainer from '../components/GradientContainer';
import MOS from '../images/001-MOS-Logo-MQ-100520-300x102.png';
import MCF from '../images/002-MCF-Badge-LQ-100520.png';
import ITS from '../images/ITS-Logo-Wide.png';
import ACP from '../images/Adobe_Certified_Professional_badge.png';
import IC3 from '../images/001-IC3-MQ-100520.png';

const courses = [
  {
    title: "Microsoft Office Specialist",
    shortDescription: "Choose from Word, Excel, PowerPoint, Outlook, Access and more",
    price: "From £200.00",
    imgSrc: MOS, 
  },
  {
    title: "Microsoft Certified Fundamentals",
    shortDescription: "Learn new skills to boost productivity",
    price: "From £200.00",
    imgSrc: MCF,
  },
  {
    title: "Information Technology Specialist",
    shortDescription: "Aimed at those wanting to validate foundational skills",
    price: "From £200.00",
    imgSrc: ITS,
  },
  {
    title: "Adobe Certified Professional",
    shortDescription: "Industry recognised certification that demonstrates mastery of Adobe software",
    price: "From £200.00",
    imgSrc: ACP,
  },
  {
    title: "IC3 Digital Literacy",
    shortDescription: "Learn new Digital literacy skills or improve on existing skills",
    price: "From £200.00",
    imgSrc: IC3,
  },
];

const PopularCourses = () => {
  return (
    <section className="bg-footerBlue">
      <div className="w-full mx-auto text-center">
        <GradientContainer className="p-8">
          <h2 className="text-4xl font-bold text-white mb-6 underline decoration-blue-500">
            Our <span className="text-yellow-300">Popular</span> Courses
          </h2>
          <p className="text-white mb-12 max-w-2xl mx-auto">
            Whether you’re looking to boost your skills in programming, understand core computer science principles, or get hands-on with the latest technology, we offer tailored courses to suit your needs. Explore our popular courses below and take the first step towards mastering your career in technology.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {courses.map((course, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="flex justify-center items-center h-48">
                  <img src={course.imgSrc} alt={course.title} className="h-36 w-auto px-4 object-contain" />
                </div>
                <div className="p-2">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">{course.title}</h3>
                  <p className="text-gray-600 mb-4">
                    <span className="">{course.shortDescription}</span>
                 </p>
                  <div className="text-blue-500 font-bold text-xl">{course.price}</div>
                </div>
              </div>
            ))}
          </div>
        </GradientContainer>
      </div>
    </section>
  );
};

export default PopularCourses;
