import React from 'react';
import GradientContainer from './GradientContainer';
import OutlinedButton from './buttons/OutlinedButton';
import picture from "../images/pexels-mikhail-nilov-9159274-removebg.png";

const SkillsSection = () => (
  <section className="text-center">
    <GradientContainer className="pt-16 pb-0">
      <div className="container mx-auto grid grid-cols-12 gap-8 items-center">

        <div className="col-span-2"></div>

        <div className="col-span-4">
          <h1 className="text-4xl md:text-5xl font-bold text-left text-white mb-4">
            Develop Your <br /> Skills in a <span className="text-yellow-400">New</span> <br /> and <span className="text-yellow-400">Unique</span> Way
          </h1>
          <p className="text-lg md:text-xl text-left text-white mb-8">
            Providing personalized support in Computer Science, IT, and Software Development. Whether you’re studying for BTEC, T-Level, or degree-level courses, we can help you succeed.          </p>
          <OutlinedButton className="mt-4">
            Enroll Now
          </OutlinedButton>
        </div>

        <div className="col-span-1"></div>

        <div className="col-span-3">
          <img src={picture} alt="Learning Image" className="h-full w-full object-cover rounded-lg" />
        </div>

      </div>
    </GradientContainer>
  </section>
);

export default SkillsSection;
