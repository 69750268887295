import React from 'react';
import logo from '../images/logo-white.png';

const Footer = () => {
  return (
    <footer className="bg-footerBlue text-white py-10">
      <div className="w-fully mx-auto px-4 grid-cols-12">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 text-center md:text-left mb-8">
        <div className="col-span-1"></div>

          {/* Column 1: Logo and Description */}
          <div className="md:col-span-3">
            <div className="flex flex-col items-center md:items-start">
              <img src={logo} alt="Teesside Bytes Logo" className="h-14 w-auto mb-4" />
              <p className="text-gray-300 text-center md:text-left">
                Explore a transformative approach to skill development on our online learning platform.
              </p>
            </div>
            <div className="flex space-x-4 mt-4 justify-center md:justify-start">
              <a href="#" className="text-white hover:text-gray-300 text-2xl">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300 text-2xl">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300 text-2xl">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300 text-2xl">
                <i className="fab fa-youtube"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300 text-2xl">
                <i className="fab fa-discord"></i>
              </a>
            </div>
          </div>

          <div className="col-span-2"></div>

          {/* Column 2: Company */}
          <div className="md:col-span-1 mx-4">
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul className="text-gray-300 space-y-2">
              <li><a href="#" className="hover:text-white">About us</a></li>
              <li><a href="#" className="hover:text-white">Services</a></li>
              <li><a href="#" className="hover:text-white">LinkedIn</a></li>
              <li><a href="#" className="hover:text-white">Privacy Policy</a></li>
            </ul>
          </div>

          {/* Column 3: Support */}
          <div className="md:col-span-1 mx-4">
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul className="text-gray-300 space-y-2">
              <li><a href="#" className="hover:text-white">Help Center</a></li>
              <li><a href="#" className="hover:text-white">Feedback</a></li>
              <li><a href="#" className="hover:text-white">Discord</a></li>
            </ul>
          </div>

          {/* Column 4: Follow Us */}
          <div className="md:col-span-1 mx-4">
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <ul className="text-gray-300 space-y-2">
              <li><a href="#" className="hover:text-white">Facebook</a></li>
              <li><a href="#" className="hover:text-white">Instagram</a></li>
              <li><a href="#" className="hover:text-white">Twitter</a></li>
              <li><a href="#" className="hover:text-white">YouTube</a></li>
            </ul>
          </div>

          {/* Column 5: Contact Us */}
          <div className="md:col-span-1">
            <h3 className="text-lg font-semibold mb-4">Contact us</h3>
            <ul className="text-gray-300 space-y-2">
              <li><span>+123 456 7890</span></li>
              <li><a href="mailto:youremail@info.com" className="hover:text-white">youremail@info.com</a></li>
            </ul>
          </div>
        </div>

        {/* Copyright Notice */}
        <div className="text-center text-gray-400 border-t border-gray-500 pt-6">
          <p>© 2024 Teesside Bytes. All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;