import React from 'react';
import { AcademicCapIcon, CurrencyDollarIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import picture from "../images/skills.png";

const BenefitsSection = () => (
  <section className="py-12 bg-white">
    <div className="container mx-auto grid grid-cols-12 gap-8 items-center">
      <div className="col-span-2"></div>

      <div className="col-span-4 rounded-3xl p-3 border-dashed border-2 border-gray-700 outline-offset-4">
        <img src={picture} alt="Learning Image" className="h-full w-full object-cover rounded-3xl bg-gradient-to-b from-blue-500 to-purple-500 px-10 " />
      </div> 

      <div className="col-span-1"></div>

      <div className="col-span-4 flex flex-col space-y-8 relative">
        <h1 className="text-3xl md:text-3xl font-bold text-gray-700 text-left">
          Unlock Your <span className="text-blue-500"> Potential </span>  <br />with Expert Tutoring in Teesside
        </h1>
        <div className="flex items-center space-x-4">
          <AcademicCapIcon className="h-20 w-20 text-blue-500" />
          <div>
            <h3 className="text-xl font-semibold text-blue-500 mb-2 underline decoration-blue-500 underline-offset-4">
              BTEC Tutoring
            </h3>
            <div className="p-4 bg-gray-100 rounded-lg">
              <p className="text-gray-600">Struggling with Level 1, 2, or 3 BTEC modules? Our tailored tutoring sessions will guide you through complex topics, helping you achieve the grades you need.</p>
            </div>
          </div>
        </div>
      
        <div className="flex items-center space-x-4">
          <CurrencyDollarIcon className="h-20 w-20 text-blue-500" />
          <div>
            <h3 className="text-xl font-semibold text-blue-500 mb-2 underline decoration-blue-500 underline-offset-4">
              T-Level Tutoring - Python
            </h3>
            <div className="p-4 bg-gray-100 rounded-lg">
              <p className="text-gray-600">Master Python programming and learn essential data structures and algorithms. Our expert tutors simplify complex concepts and offer practical, real-world examples.</p>
            </div>
          </div>
        </div>
      
        <div className="flex items-center space-x-4">
          <UserGroupIcon className="h-20 w-20 text-blue-500" />
            <div>
              <h3 className="text-xl font-semibold text-blue-500 mb-2 underline decoration-blue-500 underline-offset-4">
                Degree Tutoring
              </h3>
              <div className="p-4 bg-gray-100 rounded-lg">
                <p className="text-gray-600">Take your university studies to the next level. We offer comprehensive tutoring in Java, C++, Python, and SQL. Excel in advanced topics and get ahead in your career.</p>
              </div>
            </div>
          </div>
        </div>
      <div className="col-span-1"></div>
    </div>
  </section>
);

export default BenefitsSection;
