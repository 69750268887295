import React from 'react';
import PropTypes from 'prop-types';

const FilledButton = ({ children, onClick, className = '' }) => {
  return (
    <button
      className={`bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold px-6 py-2 rounded-lg hover:opacity-90 transition ${className}`}
    >
      {children}
    </button>
  );
};

FilledButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default FilledButton;
