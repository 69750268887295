import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import SkillsSection from './components/SkillsSection';
import CourseSection from './pages/CourseSection';
import BenefitsSection from './pages/BenefitsSection';
import TutoringSection from './pages/TutoringSection';

const Home = () => (
  <>
    <HeroSection />
    <TutoringSection />
    <Products />
  </>
);

function App() {
  return (
    <Router>
      <div>
        <Header />
        <SkillsSection />
        <BenefitsSection />
        <CourseSection />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
