import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import FilledButton from './buttons/FilledButton';
import OutlinedButton from './buttons/OutlinedButton';
import logo from '../images/logo.png';

const Header = () => {
  return (
    <header className="bg-gradient-to-l from-blue-500 to-purple-500">
      <div className="w-full bg-white shadow-md curved-section rounded-b-3xl border-0 mx-auto grid grid-cols-12 items-center py-4">
        <div className="col-span-1"></div>

        <div className="col-span-3">
          <img src={logo} alt="Teesside Bytes Logo" className="h-12 w-auto" />
        </div>

        <nav className="col-span-4 hidden md:flex justify-around">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-gray-700 hover:text-blue-500">
                Courses
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
              </MenuButton>
            </div>
          </Menu>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-gray-700 hover:text-blue-500">
                Teachers
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
              </MenuButton>
            </div>
          </Menu>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-gray-700 hover:text-blue-500">
                About Us
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
              </MenuButton>
            </div>
          </Menu>

          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-gray-700 hover:text-blue-500">
                Contact
                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
              </MenuButton>
            </div>
          </Menu>       
        </nav>

        <div className="col-span-1"></div>

        <div className="col-span-2 flex justify-end space-x-4">
          <FilledButton>Sign Up</FilledButton>
          <OutlinedButton>Log In</OutlinedButton>
        </div>
      </div>
    </header>
  );
};

export default Header;