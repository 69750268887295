import React from 'react';
import PropTypes from 'prop-types';

const OutlinedButton = ({ children, onClick, className = '' }) => {
  return (
    <button
      className={`border-2 border-transparent bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text font-semibold px-6 py-2 rounded-lg hover:bg-opacity-10 transition ${className}`}
    >
      {children}
    </button>
  );
};

OutlinedButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default OutlinedButton;
