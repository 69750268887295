import React from 'react';
import PropTypes from 'prop-types';

const GradientContainer = ({ children, className = '' }) => {
  return (
    <div
      className={`bg-gradient-to-bl from-blue-500 to-purple-500 text-white font-semibold transition bg-white shadow-md curved-section rounded-b-3xl border-0 ${className}`}
    >
      {children}
    </div>
  );
};

GradientContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default GradientContainer;
